import styled, {keyframes} from 'styled-components'

// Define the animation for the modal
const slideUpAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const ModalBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;

  @media (min-width: 768px) {
    height: 100vh;
    align-items: center;
    align-content: center;
  }
`

export const ModalContent = styled.div`
  position: relative;
  background: var(--dls-white-color);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px 14px 0 0;
  width: 100vw;
  padding: 0px;
  opacity: 1;
  transform: translateY(100%);
  animation: ${slideUpAnimation} 0.3s ease-out forwards;

  @media (min-width: 768px) {
    width: auto;
    margin: 200px;
    border-radius: 30px;
    transform: none;
    animation: none;
  }
`

export const ModalCloseButton = styled.button`
  position: absolute;
  top: calc(-1 * var(--dls-size-48));
  transform: translateX(-50%);
  left: 50%;
  right: 0;
  background-color: transparent;
  padding: var(--dls-size-8);
  border: none;
  width: var(--dls-size-48);
  height: var(--dls-size-48);
  cursor: pointer;

  svg {
    width: var(--dls-size-32);
    height: var(--dls-size-32);
  }
  @media (min-width: 768px) {
    top: calc(-1 * var(--dls-size-64));
    svg {
      width: var(--dls-size-40);
      height: var(--dls-size-40);
    }
  }
`
