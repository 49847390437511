import styled from 'styled-components'

export const NavigationButton = styled.button<{
  direction: 'left' | 'right'
  position?: 'inside' | 'outside'
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 3;
  ${({direction}) =>
    direction === 'left'
      ? 'left: 0; transform: translate(-100%, -50%);'
      : 'right: 0; transform: translate(100%, -50%);'}

  ${(props) => {
    const baseOffset = '80px'
    if (props.position === 'outside') {
      return props.direction === 'left'
        ? `left: -${baseOffset};`
        : `right: -${baseOffset};`
    }
    return props.direction === 'left'
      ? 'left: var(--dls-size-16);'
      : 'right: var(--dls-size-16);'
  }}

  &:focus {
    outline: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  svg {
    cursor: pointer;
    width: 40px;
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }

  @media screen and (min-width: 786px) {
    svg {
      cursor: pointer;
      width: 80px;
      path {
        stroke: var(--dls-brand-primary-color-500) !important;
      }
    }
  }
`

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &:hover ${NavigationButton} {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
`

export const CarouselContainer = styled.div<{isMobile: boolean}>`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  touch-action: ${(props) => (props.isMobile ? 'pan-y pinch-zoom' : 'none')};
`

export const SlideContainer = styled.div<{
  isTransitioning: boolean
  offset: number
  dragOffset: number
  isDragging: boolean
  slidesToShow: number
}>`
  display: flex;
  transform: translateX(
    calc(
      (-100% / ${(props) => props.slidesToShow || 1}) *
        ${(props) => props.offset} + ${(props) => props.dragOffset}px
    )
  );
  transition: transform
    ${(props) => (props.isTransitioning ? '500ms ease-out' : '0ms')};
  cursor: ${(props) => (props.isDragging ? 'grabbing' : 'default')};
`

export const Slide = styled.div<{width: string; slidesToShow: number}>`
  min-width: ${(props) => props.width};
  flex: 0 0 ${(props) => props.width};
  padding: 0 ${(props) => (props.slidesToShow > 1 ? 4 : 0)}px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
`

export const NavigationDots = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--dls-size-4);
  margin-top: var(--dls-size-4);
`

export const Dot = styled.button<{isActive: boolean}>`
  width: var(--dls-size-8);
  height: var(--dls-size-8);
  padding: 0;
  border-radius: 50%;
  border: 1px solid var(--dls-brand-primary-color-500);
  background-color: ${(props) =>
    props.isActive ? 'var(--dls-brand-primary-color-400)' : 'transparent'};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? 'var(--dls-brand-primary-color-500)'
        : 'var(--dls-brand-primary-color-200)'};
  }
`
