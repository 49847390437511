import React, {Children, useMemo} from 'react'
import {
  ArrowLeftWhite,
  ArrowRightWhite,
  useDeviceTypeContext,
} from '@web-components'
import useCarousel from './useCarousel'
import {
  CarouselContainer,
  CarouselWrapper,
  Dot,
  NavigationButton,
  NavigationDots,
  Slide,
  SlideContainer,
} from './styles'
import {ICarouselProps} from './types'

const Carousel = (props: ICarouselProps) => {
  const {
    showDots = true,
    showArrows,
    slidesToShow = 1,
    slidesToShowDesktop,
    children,
    autoSlide = true,
    autoSlideInterval = 3000,
    infinite = true,
    slidesToScroll = 1,
    arrowPosition,
    customSlideContainerClassName = '',
    activeSlide,
    handleSlideUpdate,
  } = props

  const {isMobile} = useDeviceTypeContext()

  const validatedNoOfSlides = useMemo(() => {
    const slidesLength = Children.toArray(children)?.length || 0
    const validNoOfSlides = isMobile
      ? Math.floor(slidesToShow)
      : slidesToShowDesktop
      ? Math.floor(slidesToShowDesktop)
      : Math.floor(slidesToShow)

    return slidesLength < validNoOfSlides ? slidesLength : validNoOfSlides
  }, [children, isMobile, slidesToShow, slidesToShowDesktop])
  const {
    slides,
    extendedSlides,
    noOfSlidesUsed,
    isTransitioning,
    isDragging,
    offset,
    dragOffset,
    slideWidth,
    touchEventProps,
    showPrevArrow,
    showNextArrow,
    showArrowsForScreen,
    handleTransitionEnd,
    handleNext,
    handlePrev,
    handleDotClick,
    getDisplayIndex,
  } = useCarousel({
    showDots,
    showArrows,
    slidesToShow: validatedNoOfSlides,
    children,
    autoSlide,
    autoSlideInterval,
    infinite,
    slidesToScroll,
    isMobile,
    activeSlide,
    handleSlideUpdate,
  })

  return (
    <CarouselWrapper>
      {showArrowsForScreen && showPrevArrow && (
        <NavigationButton
          direction="left"
          position={arrowPosition || (isMobile ? 'inside' : 'outside')}
          onClick={() => handlePrev()}
          disabled={isTransitioning}
          aria-label="Previous slide"
        >
          <ArrowLeftWhite aria-hidden />
        </NavigationButton>
      )}

      <CarouselContainer isMobile={isMobile}>
        <SlideContainer
          isTransitioning={isTransitioning && !isDragging}
          offset={offset}
          dragOffset={dragOffset}
          isDragging={isDragging}
          slidesToShow={noOfSlidesUsed}
          onTransitionEnd={handleTransitionEnd}
          className={customSlideContainerClassName}
          {...touchEventProps}
        >
          {extendedSlides.map((slide, index) => (
            <Slide
              key={index}
              width={slideWidth}
              aria-label={`Slide ${index + 1}`}
              slidesToShow={noOfSlidesUsed}
            >
              {slide}
            </Slide>
          ))}
        </SlideContainer>

        {showDots && slides.length > 1 && (
          <NavigationDots>
            {slides.map((_, index) => (
              <Dot
                key={index}
                isActive={getDisplayIndex() === index}
                onClick={() => handleDotClick(index)}
                disabled={isTransitioning}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </NavigationDots>
        )}
      </CarouselContainer>

      {showArrowsForScreen && showNextArrow && (
        <NavigationButton
          direction="right"
          position={arrowPosition || (isMobile ? 'inside' : 'outside')}
          onClick={() => handleNext()}
          disabled={isTransitioning}
          aria-label="Next slide"
        >
          <ArrowRightWhite aria-hidden />
        </NavigationButton>
      )}
    </CarouselWrapper>
  )
}

export default Carousel
