import styled from 'styled-components'

export const MarqueeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: var(--dls-white-color);
  padding: 10px 0;
  position: relative;

  .marquee {
    display: flex;
    gap: 10px;
    white-space: nowrap;
    animation: scrollLeft 16s linear infinite;

    @keyframes scrollLeft {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }

    @media (max-width: 768px) {
      animation: scrollLeft 26s linear infinite;
      @keyframes scrollLeft {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-300%);
        }
      }
    }
  }

  .marquee-item {
    margin: 0 15px;
    font-size: var(--dls-size-16);
    font-weight: bold;
    color: var(--dls-brand-primary-color-500);
  }

  .separator {
    margin: 0 5px;
    color: var(--dls-brand-primary-color-500);
    font-weight: bold;
  }
`
