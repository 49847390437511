import React from 'react'
import {MarqueeContainer} from './styles'
import {Typography, TypographyVariants} from '@web-components'
import {IMarquee} from './types'

const Marquee = (props: IMarquee) => {
  const {items = []} = props || {}

  if (items.length === 0) return null

  return (
    <MarqueeContainer>
      <div className="marquee">
        {items?.map((item: string, index: number) => (
          <React.Fragment key={index}>
            <span className="marquee-item">
              <Typography variant={TypographyVariants.X_SMALL}>
                {item}
              </Typography>
            </span>
            <span className="separator">•</span>
          </React.Fragment>
        ))}

        {items?.map((item: string, index: number) => (
          <React.Fragment key={`duplicate-${index}`}>
            <span className="marquee-item">
              <Typography variant={TypographyVariants.X_SMALL}>
                {item}
              </Typography>
            </span>
            <span className="separator">•</span>
          </React.Fragment>
        ))}
      </div>
    </MarqueeContainer>
  )
}

export default Marquee
