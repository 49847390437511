import React, {memo, useEffect, useState, useRef} from 'react'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import {
  MediaPreviewModalProps,
  ZoomComponentProps,
} from './MediaPreviewModal.interface'
import Modal from '../Modal'
import noop from 'lodash/noop'
import {
  MediaPreviewModalWrapper,
  StyledSliderItem,
} from './MediaPreviewModal.styles'
import Carousel from '../Carousel'
import Media from '../Media'
import Slider from '../Slider'
import {
  AccordionRight,
  Close,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {useDeviceTypeContext} from '@web-components'

const ZoomMedia = ({
  media,
  index,
  aspectRatio,
  resetTransform,
}: ZoomComponentProps) => {
  useEffect(() => {
    resetTransform()
  }, [])

  return (
    <TransformComponent>
      <Media
        key={'media_item_' + index}
        media={media}
        aspectRatio={aspectRatio}
        customClassName="tile-image"
      />
    </TransformComponent>
  )
}

const MediaPreviewModal = ({
  showModal = false,
  onClose = noop,
  carouselConfig = {},
  items = [],
  sliderConfig = {},
  defaultSlide = 0,
  headerTitle = '',
}: MediaPreviewModalProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(defaultSlide)
  const {isMobile} = useDeviceTypeContext()
  const handleSliderItemClick = (index: number) => () => {
    setActiveSlide(index)
  }
  const handleSlideUpdate = (index: number) => {
    setActiveSlide(index)
  }
  useEffect(() => {
    setActiveSlide(defaultSlide)
  }, [showModal, defaultSlide])

  if (!showModal) {
    return null
  }

  return (
    <MediaPreviewModalWrapper>
      <Modal
        showModal={showModal}
        hideCloseButton={true}
        onClose={onClose}
        additionalContentClass="preview-modal-content"
      >
        <div className="header">
          <div className="right-container">
            <div className="accordion" onClick={onClose}>
              <AccordionRight stroke="var(--dls-brand-primary-color-500)" />
            </div>
            <Typography variant={TypographyVariants.HEADING_SMALL_REGULAR}>
              {headerTitle}
            </Typography>
          </div>
          <div className="close-cta" onClick={onClose}>
            <Close
              height={16}
              width={16}
              stroke="var(--dls-brand-primary-color-500)"
            />
          </div>
        </div>
        <div className="carousel-container">
          <Carousel
            showDots={carouselConfig?.showDots || false}
            showArrows={carouselConfig?.showArrows ?? true}
            slidesToShow={carouselConfig?.slidesToShow ?? 1}
            slidesToShowDesktop={carouselConfig?.slidesToShowDesktop ?? 1}
            autoSlide={carouselConfig?.autoSlide || false}
            autoSlideInterval={carouselConfig?.autoSlideInterval || 2000}
            infinite={carouselConfig?.infinite ?? true}
            slidesToScroll={carouselConfig?.slidesToScroll ?? 1}
            arrowPosition={carouselConfig?.arrowPosition ?? 'inside'}
            customSlideContainerClassName="carousel-slide-container"
            activeSlide={activeSlide}
            handleSlideUpdate={handleSlideUpdate}
          >
            {items.map((item: any, index: number) => {
              const mediaProps = {...item?.media, muted: false, controls: true}

              return (
                <div key={index} className="carousel-data-container">
                  <TransformWrapper
                    centerOnInit={true}
                    initialScale={isMobile ? 1 : 0.65}
                    doubleClick={{mode: 'reset'}}
                  >
                    {({resetTransform}) => {
                      resetTransform()
                      return (
                        <ZoomMedia
                          media={mediaProps}
                          index={index}
                          resetTransform={resetTransform}
                          aspectRatio={item?.aspectRatio}
                        />
                      )
                    }}
                  </TransformWrapper>
                </div>
              )
            })}
          </Carousel>
        </div>

        <div className="slider-container">
          <Slider
            aspectRatio={sliderConfig?.aspectRatio ?? 1}
            slidesToShow={sliderConfig?.slidesToShow ?? 5}
            slidesToShowDesktop={sliderConfig?.slidesToShowDesktop ?? 7}
            showPeek={sliderConfig?.showPeek ?? true}
            peekVariant={sliderConfig?.peekVariant ?? 'SMALL'}
            showArrows={sliderConfig?.showArrows || false}
            itemCount={items.length}
            activeSlide={activeSlide}
            customOuterContainerClassName="slider-outer-container"
          >
            {() =>
              items.map((item: any, index: number) => {
                const mediaProps = {
                  ...item.media,
                  autoplay: false,
                  controls: false,
                }

                return (
                  <StyledSliderItem
                    key={'slider_item_' + index}
                    data-isActive={index === activeSlide}
                    onClick={handleSliderItemClick(index)}
                  >
                    <Media
                      media={mediaProps}
                      desktopViewWidth={'THIRD'}
                      mobileViewWidth={'THIRD'}
                      aspectRatio={item?.aspectRatio}
                      customClassName="tile-image"
                    />
                  </StyledSliderItem>
                )
              })
            }
          </Slider>
        </div>
      </Modal>
    </MediaPreviewModalWrapper>
  )
}

export default MediaPreviewModal
