import styled from 'styled-components'

export const MediaPreviewModalWrapper = styled.div`
  .preview-modal-content {
    background-color: var(--dls-backgound-color-500);
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--dls-size-32);
      color: transparent;
      position: absolute;
      top: 0;
      width: 100%;
      .right-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--dls-size-4);
        color: var(--dls-brand-primary-color-500);
        z-index: 1;
        background-color: var(--dls-backgound-color-500);
        .accordion {
          cursor: pointer;
          transform: rotate(180deg);
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      .close-cta {
        width: var(--dls-size-48);
        height: var(--dls-size-48);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid var(--dls-brand-primary-color-500);
        z-index: 1;
        background-color: var(--dls-backgound-color-500);
      }
    }
    .carousel-container {
      flex: 1;
      display: flex;
      align-items: center;
      .carousel-slide-container {
      }
      .carousel-data-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      }
      .tile-image {
      }
    }
    .slider-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--dls-size-16);
      position: absolute;
      bottom: 0;
      width: 100%;
      .slider-outer-container {
        width: fit-content;
      }
      .tile-image {
        max-width: 80px;
      }
    }
  }

  .react-transform-wrapper {
    overflow: visible !important;
  }
`

export const StyledSliderItem = styled.div`
  &[data-isActive='true'] {
    border: var(--dls-size-1) solid var(--dls-brand-primary-color-500);
  }
  .tile-image {
    cursor: pointer;
  }
`
