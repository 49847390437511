import styled from 'styled-components'

export const GenericSliderWrapper = styled.section<{
  arrowPosition?: 'inside' | 'outside'
}>`
  position: relative;
  width: 100%;

  // When arrows are outside, remove default padding to prevent double spacing
  ${(props) =>
    props.arrowPosition === 'outside' &&
    `
    margin: 0 auto;
  `}
`

export const SliderControls = styled.div<{
  arrowPosition?: 'inside' | 'outside'
  isMobileView?: boolean
}>`
  position: absolute;
  width: ${(props) =>
    props.arrowPosition === 'outside'
      ? 'calc(100% + var(--dls-size-32))'
      : '100%'};
  left: ${(props) =>
    props.arrowPosition === 'outside' ? 'calc(-1 * var(--dls-size-16))' : '0'};
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 786px) {
    width: ${(props) =>
      props.arrowPosition === 'outside'
        ? 'calc(100% + var(--dls-size-160))'
        : '100%'};
    left: ${(props) =>
      props.arrowPosition === 'outside'
        ? 'calc(-1 * var(--dls-size-80))'
        : '0'};
  }

  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &:focus {
      outline: none !important;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .left {
    position: absolute;
    left: ${(props) =>
      props.arrowPosition === 'outside' ? '0' : 'var(--dls-size-16)'};

    transform: translate(-100%, -50%);
  }

  .right {
    position: absolute;
    right: ${(props) =>
      props.arrowPosition === 'outside' ? '0' : 'var(--dls-size-16)'};
    transform: translate(100%, -50%);
  }

  .arrow-visible {
    visibility: visible;
  }

  .arrow-hidden {
    visibility: hidden;
  }

  svg {
    cursor: pointer;
    width: ${(props) => (props.isMobileView ? '50px' : '80px')};
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }
`

export const OuterSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &:hover ${SliderControls} {
    opacity: 1;
    .left {
      transform: translateY(-50%) translateX(0);
    }

    .right {
      transform: translateY(-50%) translateX(0);
    }
  }
`

export const GenericSliderContainer = styled.div<{
  noOfSlides: number
  peekSize: number
  isMobileView?: boolean
}>`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-snap-type: x mandatory;
  gap: ${(props) =>
    props.isMobileView ? 'var(--dls-size-8)' : 'var(--dls-size-16)'};
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    ${({noOfSlides, peekSize, isMobileView}) => {
      const peekFactor = (100 - peekSize) / 100
      const gapSize = isMobileView ? 8 : 16 // 8px for mobile, 16px for desktop
      const marginSize = isMobileView ? 16 : 0 // 16px margin for mobile view
      const slideValue = isMobileView
        ? `calc((100% * ${peekFactor} - ${
            marginSize + Math.floor(noOfSlides - 1) * gapSize
          }px)/${noOfSlides})`
        : `calc((100% * ${peekFactor} - ${
            Math.floor(noOfSlides - 1) * gapSize
          }px) / ${noOfSlides})`

      return `
        width: ${slideValue};
        flex: 0 0 ${slideValue};
      `
    }};
    scroll-snap-align: start;
    scroll-behavior: smooth;
    scroll-margin-left: ${(props) => (props.isMobileView ? '16px' : '0')};
    scroll-margin-right: ${(props) => (props.isMobileView ? '16px' : '0')};

    :first-child {
      margin-left: ${(props) => (props.isMobileView ? '16px' : '0')};
    }
    :last-child {
      margin-right: ${(props) => (props.isMobileView ? '16px' : '0')};
    }
  }
`
