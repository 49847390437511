import {useEffect, useRef, useState, useMemo} from 'react'
import {UseSliderStateProps} from './types'

export const getImageDimensionFromAspectRatioFor360 = ({
  aspectRatio = 1,
  noOfSlides = 1,
}) => {
  const availableWidth = 360 - Math.floor(noOfSlides) * 8 // 360 viewport & rest padding
  const slideWidth = availableWidth / noOfSlides
  const slideHeight = slideWidth / aspectRatio
  return [slideWidth, slideHeight]
}

const peekSizeMap = {SMALL: 10, MEDIUM: 20, LARGE: 30}

export const useSlider = ({
  noOfSlides,
  aspectRatio,
  onSlideChange,
  showPeek = false,
  peekVariant = 'SMALL',
  showArrows,
  isMobile,
  itemCount,
  activeSlide,
}: UseSliderStateProps) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const [currentSlide, setCurrentSlide] = useState(activeSlide || 0)
  const [isEndOfScroll, setIsEndOfScroll] = useState(false)

  // Map peek variants to percentages

  const peekSize =
    showPeek && itemCount !== noOfSlides ? peekSizeMap[peekVariant] : 0

  const showArrowsForScreen = useMemo(() => {
    if (itemCount <= 1 || itemCount <= noOfSlides) return false
    return showArrows !== undefined ? showArrows : isMobile ? false : true
  }, [isMobile, itemCount, noOfSlides, showArrows])

  const imageDimensions: number[] = useMemo(() => {
    return getImageDimensionFromAspectRatioFor360({
      aspectRatio: aspectRatio || 1,
      noOfSlides: noOfSlides || 1,
    })
  }, [aspectRatio, noOfSlides])

  useEffect(() => {
    if (typeof activeSlide === 'number') {
      return
    }
    const handleScroll = () => {
      if (sliderRef.current && sliderRef.current.children.length > 0) {
        const slideWidth = sliderRef.current.children[0].clientWidth
        const scrollPosition = sliderRef.current.scrollLeft
        const scrollableWidth =
          sliderRef.current.scrollWidth - sliderRef.current.clientWidth

        const newIndex = Math.round(scrollPosition / slideWidth)

        setCurrentSlide(newIndex)
        onSlideChange?.(newIndex)

        setIsEndOfScroll(scrollPosition + slideWidth / 10 >= scrollableWidth)
      }
    }

    const currentSlider = sliderRef.current
    currentSlider?.addEventListener('scroll', handleScroll)
    return () => {
      currentSlider?.removeEventListener('scroll', handleScroll)
    }
  }, [imageDimensions, onSlideChange])

  useEffect(() => {
    if (typeof activeSlide !== 'number') {
      return
    }
    const activeSlideElement = sliderRef?.current?.children?.[activeSlide]
    if (activeSlideElement) {
      activeSlideElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      setCurrentSlide(activeSlide)
    }
  }, [activeSlide])

  const handleNextClick = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.children[0].clientWidth
      sliderRef.current.scrollBy({left: slideWidth, behavior: 'smooth'})
    }
  }

  const handlePrevClick = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.children[0].clientWidth
      sliderRef.current.scrollBy({left: -slideWidth, behavior: 'smooth'})
    }
  }

  return {
    sliderRef,
    noOfSlidesUsed: noOfSlides || 1,
    currentSlide,
    isEndOfScroll,
    imageDimensions,
    handleNextClick,
    handlePrevClick,
    peekSize,
    showArrowsForScreen,
  }
}
