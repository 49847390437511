import {
  MinusIcon,
  PlusIcon,
  Spinner,
  Typography,
  TypographyVariants,
} from '@web-components'
import React from 'react'

interface ProductCardButtonProps {
  showQuantity: boolean
  showLoader: boolean
  quantity: number
  handleReduceQuantity: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleIncreaseQuantity: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCtaClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  ctaLabel: string
}

const ProductCardButton: React.FC<ProductCardButtonProps> = ({
  showQuantity,
  showLoader,
  quantity,
  handleReduceQuantity,
  handleIncreaseQuantity,
  onCtaClick,
  ctaLabel,
}) => {
  return (
    <div className="button-container">
      {showQuantity ? (
        <div className="qty-button-container">
          <button
            onClick={handleReduceQuantity}
            className="qty-btn remove-btn"
            data-isdisabled={showLoader}
            disabled={showLoader}
          >
            <MinusIcon
              height={20}
              width={20}
              fill={'var(--dls-brand-primary-color-500)'}
            />
          </button>
          <div className="qty-label-container">
            {showLoader ? (
              <Spinner
                color={'var(--dls-brand-primary-color-500)'}
                size={'20'}
              />
            ) : (
              <Typography
                variant={TypographyVariants.CTA_LABEL_SMALL}
                customClassName="qty-label"
              >
                {quantity}
              </Typography>
            )}
          </div>
          <button
            onClick={handleIncreaseQuantity}
            className="qty-btn add-btn"
            data-isdisabled={showLoader}
            disabled={showLoader}
          >
            <PlusIcon
              height={20}
              width={20}
              fill={'var(--dls-brand-primary-color-500)'}
            />
          </button>
        </div>
      ) : (
        <button
          className="atc-btn"
          data-location="product-card"
          onClick={onCtaClick}
        >
          {showLoader ? (
            <Spinner color={'var(--dls-brand-primary-color-500)'} size={'20'} />
          ) : (
            <Typography
              variant={TypographyVariants.CTA_LABEL_SMALL}
              customClassName="atc-label"
            >
              {ctaLabel}
            </Typography>
          )}
        </button>
      )}
    </div>
  )
}

export default ProductCardButton
