import styled from 'styled-components'

export const ProductCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  @media (min-width: 768px) {
    max-width: 500px;
  }

  .quick-add-holder {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 3;
  }

  .quick-add-button {
    position: absolute;
    top: -40px;
    border: none;
    display: flex;
    justify-content: center;
    background: transparent;
    &:focus {
      outline: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  .item-info {
    margin-top: 20px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: var(--dls-size-8);
  }

  .item-price {
    display: flex;
    gap: var(--dls-size-8);
    align-items: center;
    justify-content: center;

    .strikethrough {
      text-decoration: line-through;
      color: var(--dls-color-gray-500);
    }
  }
`
