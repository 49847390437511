import React from 'react'
import {IBannerWithTextCta} from './types'
import {BannerWithTextAndCtaContainer} from './styles'
import {OptimizedImage, Typography, TypographyVariants} from '../../atomic'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

const BannerWithTextAndCta = (props: IBannerWithTextCta) => {
  const {state, actions} = props || {}
  const {banner} = state || {}

  const {triggerClickOnBannerCta = noop, handleClickOnBannerCta = noop} =
    actions || {}

  const {
    image = '',
    backgroundColor = '',
    title = '',
    subtitle = '',
    cta,
    analytics,
  } = banner || {}

  const {label = '', ctaBorderColor, ctaTextColor, action={}} = cta || {}
  const {label: text = ''} = analytics || {}

  const handleClickOnBanner = () => {
    triggerClickOnBannerCta(text)
  }

  if (isEmpty(banner)) {
    return null
  }

  return (
    <BannerWithTextAndCtaContainer
      backgroundColor={backgroundColor}
      ctaBorderColor={ctaBorderColor}
      ctaTextColor={ctaTextColor}
      onClick={handleClickOnBanner}
    >
      <div className="banner_image_text_container">
        <OptimizedImage source={image} aspectWidth={44} aspectHeight={44} />
        <div className="title_subtitle_container">
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="text"
          >
            {title}
          </Typography>
          <Typography
            variant={TypographyVariants.SUBHEADING_BOLD}
            customClassName="text"
          >
            {subtitle}
          </Typography>
        </div>
      </div>
      <div className="cta_container" onClick={() => handleClickOnBannerCta(action)}>
        <div className="cta">
          <Typography
            variant={TypographyVariants.BODY_BASE_BOLD}
            customClassName="cta_text"
          >
            {label}
          </Typography>
        </div>
      </div>
    </BannerWithTextAndCtaContainer>
  )
}

export default BannerWithTextAndCta
