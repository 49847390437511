import React from 'react'

import {isEmpty} from 'lodash'
import {Typography, TypographyVariants} from '@web-components'
import {ProductOverlayTagContainer} from './styles'

interface ProductOverlayTagProps {
  overlayTag?: {
    label: string
    labelColor: string
    bgColor: string
    borderColor: string
  }
  label?: string
  outOfStock?: boolean
}

export const ProductOverlayTag: React.FC<ProductOverlayTagProps> = ({
  overlayTag,
  outOfStock,
}) => {
  if (!isEmpty(overlayTag)) {
    const {
      label = '',
      labelColor = '',
      bgColor = '',
      borderColor = '',
    } = overlayTag || {}
    return (
      <ProductOverlayTagContainer
        bgColor={bgColor}
        borderColor={borderColor}
        labelColor={labelColor}
      >
        <div className="overlay-container">
          <Typography
            customClassName="overlay-text"
            variant={TypographyVariants.X_SMALL}
          >
            {label}
          </Typography>
        </div>
      </ProductOverlayTagContainer>
    )
  }

  if (outOfStock) {
    return (
      <ProductOverlayTagContainer>
        <div className="out-of-stock-container">
          <Typography variant={TypographyVariants.X_SMALL}>
            Out Of Stock
          </Typography>
        </div>
      </ProductOverlayTagContainer>
    )
  }

  return null
}

export default ProductOverlayTag
