import styled from 'styled-components'

export const MediaWithCtaContainer = styled.div`
  .media-container {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .media-component {
    height: auto;
    object-fit: cover;
  }
  .media-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .media-cta {
    margin: var(--dls-size-16);
  }
`
