import React, {useCallback, useMemo} from 'react'
import {ProductCardWrapper} from './CatalogCard.styles'
import {
  AddIconCircle,
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {ICatalogCard} from './CatalogCard.interface'
import noop from 'lodash/noop'

const CatalogCard = (props: ICatalogCard) => {
  const {product, handleCardClick = noop, handleCtaClick = noop} = props

  const {
    image = [],
    media,
    name = '',
    id = '',
    cta,
    cardClickActions,
    priceLabel = '',
    discountedPriceLabel = '',
    slugUrl = '#',
    aspectRatio,
  } = product || {}
  const {actions} = cta || {}

  const onCardClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleCardClick(cardClickActions, product)
    },
    [cardClickActions, handleCardClick, product]
  )

  const onCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleCtaClick(actions, product)
    },
    [actions, handleCtaClick, product]
  )
  const imageUrl = useMemo(() => {
    return image?.length > 0 ? image[0] : ''
  }, [image])

  if (product === null) {
    return null
  }

  return (
    <ProductCardWrapper onClick={onCardClick} key={id} href={slugUrl}>
      {media ? (
        <Media
          media={media}
          aspectRatio={aspectRatio}
          mobileViewWidth="THIRD"
          desktopViewWidth="THIRD"
        />
      ) : (
        <OptimizedImage
          source={imageUrl}
          altText={name}
          mobileViewWidth="THIRD"
          desktopViewWidth="THIRD"
        />
      )}

      <div className="quick-add-holder">
        <button className="quick-add-button" onClick={onCtaClick}>
          <AddIconCircle size="48" />
        </button>
      </div>
      <div className="item-info">
        <div className="item-title">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {name}
          </Typography>
        </div>
        {discountedPriceLabel ? (
          <div className="item-price">
            <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
              {discountedPriceLabel}
            </Typography>
            <Typography
              variant={TypographyVariants.BODY_BASE_LIGHT}
              customClassName="strikethrough "
            >
              {priceLabel}
            </Typography>
          </div>
        ) : (
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {priceLabel}
          </Typography>
        )}
      </div>
    </ProductCardWrapper>
  )
}

export default CatalogCard
