import {GenericAction} from '@mosaic-wellness/fe-types'
import {Button, ButtonProps, Media} from '@web-components'

import noop from 'lodash/noop'
import {MediaProps} from '../../molecules/Media/Media.interface'
import {MediaWithCtaContainer} from './styles'

export interface MediaWithCtaProps {
  data: IData
  aspectRatio?: number
  onClickActions?: (actions: GenericAction[], label?: string) => void
  index?: number
  isActive?: boolean
  customClass?: string
}

interface IData {
  media: MediaProps['media']
  slug?: string
  cta?: Omit<ButtonProps, 'onClick'> & {actions?: GenericAction[]}
}

const MediaWithCta = ({
  data,
  aspectRatio,
  onClickActions = noop,
  isActive = true,
  customClass = '',
}: MediaWithCtaProps) => {
  const {media, slug = '#', cta} = data
  const {
    label,
    variant = 'PRIMARY',
    size = 'SMALL',
    actions,
    ...rest
  } = cta || {}

  return (
    <MediaWithCtaContainer>
      <a
        className={`media-container ${customClass ?? ''}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (isActive && actions) onClickActions(actions, label)
        }}
        href={slug}
      >
        <Media
          media={media}
          aspectRatio={aspectRatio}
          customClassName="media-component"
        />
        {label ? (
          <div className="media-overlay">
            <Button
              variant={variant}
              label={label}
              onClick={noop}
              customClassName="media-cta"
              size={size}
              {...rest}
            />
          </div>
        ) : null}
      </a>
    </MediaWithCtaContainer>
  )
}

export default MediaWithCta
