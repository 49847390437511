import {Typography, TypographyVariants} from '@web-components'
import React from 'react'

interface ProductInfoProps {
  For?: string
  name: string
  showDiscountedPrice: boolean
  discountPriceLabel?: string
  priceLabel: string
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  For,
  name,
  showDiscountedPrice,
  discountPriceLabel,
  priceLabel,
}) => {
  return (
    <div className="product-info">
      {For && (
        <Typography
          variant={TypographyVariants.X_SMALL}
          customClassName="for-label"
        >
          {For}
        </Typography>
      )}
      <div className="product-name-container">
        <Typography
          variant={TypographyVariants.CTA_LABEL_SMALL}
          customClassName="product-name"
        >
          {name}
        </Typography>
      </div>
      <div className="pricing">
        {showDiscountedPrice ? (
          <div className="price-ctn">
            <div>
              <Typography
                variant={TypographyVariants.BODY_BASE_BOLD}
                customClassName="price"
              >
                {discountPriceLabel}
              </Typography>
            </div>
            <div>
              <Typography
                variant={TypographyVariants.X_SMALL}
                customClassName="discounted-price"
              >
                {priceLabel}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="price-top-container">
            <Typography
              variant={TypographyVariants.BODY_BASE_BOLD}
              customClassName="price price-ctn"
            >
              {priceLabel}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductInfo
