import React, {useCallback, useMemo} from 'react'
import {ProductCardContainer} from './ProductCard.styles'
import {
  ReviewRatingStar,
  Typography,
  TypographyVariants,
  Media,
} from '@web-components'
import noop from 'lodash/noop'
import {ProductCardProps} from './ProductCard.types'
import ProductInfo from './ProductInfo'
import ProductCardButton from './ProductCardButton'
import ProductOverlayTag from './ProductOverlayTag'

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    product,
    productImageAspectRatio = 1,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
    enableQuantityIndicator = false,
    showImageBorder = true,
    isCartUpdating = false,
    itemBeingAddedToCart = '',
    cartItems,
  } = props || {}
  const {
    id,
    discountedPrice = '',
    discountPriceLabel = '',
    name = '',
    price = '',
    priceLabel = '',
    rating = '',
    ctaLabel = 'Add to Cart',
    For = '',
    sku = '',
    media,
    slugUrl = '',
    outOfStock = false,
    overlayTag,
  } = product || {}

  const quantity = useMemo(() => cartItems?.[sku]?.qty || 0, [cartItems, sku])
  const showQuantity = useMemo(
    () => quantity > 0 && enableQuantityIndicator,
    [quantity, enableQuantityIndicator]
  )

  const showLoader = useMemo(() => {
    return isCartUpdating && itemBeingAddedToCart === sku
  }, [isCartUpdating, itemBeingAddedToCart, sku])

  const showDiscountedPrice = useMemo(() => {
    return discountedPrice && discountedPrice !== 0 && price > discountedPrice
  }, [discountedPrice, price])

  const handleReduceQuantity = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity - 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const handleIncreaseQuantity = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity + 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const onCardClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleCardClick(product)
    },
    [handleCardClick, product]
  )

  const onCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleCtaClick(product)
    },
    [handleCtaClick, product]
  )

  return (
    <ProductCardContainer
      href={slugUrl}
      onClick={onCardClick}
      outOfStock={outOfStock}
      productImageAspectRatio={productImageAspectRatio}
      showImageBorder={showImageBorder}
    >
      <div className="img-container">
        {Number(rating) > 0 && (
          <div className="rating-container">
            <ReviewRatingStar count={1} value={Number(rating)} size={12} />
            <div className="ratinginfo">
              {Number(rating) > 0 && (
                <Typography variant={TypographyVariants.X_SMALL}>
                  {rating}
                </Typography>
              )}
            </div>
          </div>
        )}
        <Media
          media={media}
          desktopViewWidth={'THIRD'}
          mobileViewWidth={'THIRD'}
          aspectRatio={productImageAspectRatio}
          customClassName="product-image"
        />
        <ProductOverlayTag overlayTag={overlayTag} outOfStock={outOfStock} />
      </div>

      <ProductInfo
        For={For}
        name={name}
        showDiscountedPrice={showDiscountedPrice}
        discountPriceLabel={discountPriceLabel}
        priceLabel={priceLabel}
      />

      <ProductCardButton
        showQuantity={showQuantity}
        showLoader={showLoader}
        quantity={quantity}
        handleReduceQuantity={handleReduceQuantity}
        handleIncreaseQuantity={handleIncreaseQuantity}
        onCtaClick={onCtaClick}
        ctaLabel={ctaLabel}
      />
    </ProductCardContainer>
  )
}

export default ProductCard
