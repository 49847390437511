import {
  Media,
  MinusIcon,
  OptimizedImage,
  PlusIcon,
  Spinner,
  Typography,
  TypographyVariants,
} from '@web-components'
import {HorizontalProductCardContainer} from './HorizontalProductCard.styles'
import {HorizontalProductCardProps} from './HorizontalProductCard.types'
import ProductOverlayTag from '../ProductCard/ProductOverlayTag'
import {noop} from 'lodash'
import {useCallback, useMemo} from 'react'
import ProductCardButton from '../ProductCard/ProductCardButton'

const HorizontalProductCard = (props: HorizontalProductCardProps) => {
  const {
    product,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
    productImageAspectRatio = 1,
    enableQuantityIndicator = false,
    isCartUpdating = false,
    itemBeingAddedToCart = '',
    cartItems,
  } = props || {}
  const {
    id,
    discountedPrice = '',
    discountPriceLabel = '',
    name = '',
    price = '',
    priceLabel = '',
    ctaLabel = 'Add to Cart',
    For = '',
    sku = '',
    media,
    slugUrl = '',
    outOfStock = false,
    overlayTag,
  } = product || {}

  const quantity = useMemo(() => cartItems?.[sku]?.qty || 0, [cartItems, sku])
  const showQuantity = useMemo(
    () => quantity > 0 && enableQuantityIndicator,
    [quantity, enableQuantityIndicator]
  )

  const showLoader = useMemo(() => {
    return isCartUpdating && itemBeingAddedToCart === sku
  }, [isCartUpdating, itemBeingAddedToCart, sku])

  const showDiscountedPrice = useMemo(() => {
    return discountedPrice && discountedPrice !== 0 && price > discountedPrice
  }, [discountedPrice, price])

  const handleReduceQuantity = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity - 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const handleIncreaseQuantity = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity + 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const onCardClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleCardClick(product)
    },
    [handleCardClick, product]
  )

  const onCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleCtaClick(product)
    },
    [handleCtaClick, product]
  )

  if (!product) return null

  return (
    <HorizontalProductCardContainer
      outOfStock={outOfStock}
      href={slugUrl}
      onClick={onCardClick}
    >
      <div className="img-container">
        <Media
          media={media}
          desktopViewWidth={'THIRD'}
          mobileViewWidth={'THIRD'}
          aspectRatio={productImageAspectRatio}
          customClassName="product-image"
        />
        <ProductOverlayTag overlayTag={overlayTag} outOfStock={outOfStock} />
      </div>
      <div className="content-container">
        <div className="content-wrapper">
          <Typography
            customClassName="product-for-text"
            variant={TypographyVariants.X_SMALL}
          >
            {For}
          </Typography>
          <div className="product-name-container">
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {name}
            </Typography>
          </div>
        </div>

        <div className="pricing-and-cta-container">
          <div className="pricing-container">
            {showDiscountedPrice ? (
              <>
                <Typography variant={TypographyVariants.TAG_BOLD}>
                  {discountPriceLabel}
                </Typography>
                <Typography
                  customClassName="discounted-price-text"
                  variant={TypographyVariants.X_SMALL}
                >
                  {priceLabel}
                </Typography>
              </>
            ) : (
              <Typography variant={TypographyVariants.TAG_BOLD}>
                {priceLabel}
              </Typography>
            )}
          </div>

          <ProductCardButton
            showQuantity={showQuantity}
            showLoader={showLoader}
            quantity={quantity}
            handleReduceQuantity={handleReduceQuantity}
            handleIncreaseQuantity={handleIncreaseQuantity}
            onCtaClick={onCtaClick}
            ctaLabel={ctaLabel}
          />
        </div>
      </div>
    </HorizontalProductCardContainer>
  )
}

export default HorizontalProductCard
