import React, {memo} from 'react'
import {OptimizedImage, OptimizedVideo, LottiePlayer} from '../../atomic'
import {MediaWrapper} from './Media.styles'
import {
  MediaProps,
  MediaImage,
  MediaVideo,
  MediaLottie,
} from './Media.interface'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

const DEFAULT_IMAGE_WIDTH = 360

const Media = ({
  media,
  desktopViewWidth,
  mobileViewWidth,
  aspectRatio = 1,
  customClassName = '',
  onClick = noop,
}: MediaProps) => {
  const {mediaType} = media || {}
  if (!mediaType && isEmpty(media)) return null

  return (
    <MediaWrapper
      aspectRatio={aspectRatio}
      className="media-wrapper"
      data-testid="media-wrapper"
      onClick={onClick}
    >
      {mediaType === 'image' && (
        <OptimizedImage
          source={(media as MediaImage).source}
          aspectWidth={DEFAULT_IMAGE_WIDTH}
          aspectHeight={DEFAULT_IMAGE_WIDTH / aspectRatio}
          desktopViewWidth={desktopViewWidth ?? 'FULL'}
          mobileViewWidth={mobileViewWidth ?? 'FULL'}
          loading={(media as MediaImage).loading ?? 'lazy'}
          altText={(media as MediaImage).altText}
          customClassName={customClassName}
        />
      )}
      {mediaType === 'video' && (
        <OptimizedVideo
          state={{
            source: (media as MediaVideo).source,
            autoplay: (media as MediaVideo).autoplay ?? true,
            loop: (media as MediaVideo).loop ?? true,
            muted: (media as MediaVideo).muted ?? true,
            controls: (media as MediaVideo).controls ?? false,
            posterImage: (media as MediaVideo).posterImage,
            playsInline: (media as IVideo).playsInline ?? true,
            customClassName,
          }}
        />
      )}
      {mediaType === 'lottie' && (
        <LottiePlayer
          src={(media as MediaLottie).source}
          autoplay={(media as MediaLottie).autoplay ?? true}
          loop={(media as MediaLottie).loop ?? true}
          style={{width: '100%', height: 'auto'}}
          className={customClassName}
        />
      )}
    </MediaWrapper>
  )
}

export default memo(Media)
