import {Children, useCallback, useEffect, useMemo, useState} from 'react'
import {ICarouselProps} from './types'
import noop from 'lodash/noop'

const useCarousel = (props: ICarouselProps) => {
  const {
    children,
    showArrows,
    autoSlide = true,
    autoSlideInterval = 3000,
    infinite = true,
    slidesToShow = 3,
    slidesToScroll = 1,
    isMobile,
    activeSlide = 0,
    handleSlideUpdate = noop,
  } = props

  const slides = Children.toArray(children)
  const [currentIndex, setCurrentIndex] = useState(activeSlide)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [offset, setOffset] = useState(
    activeSlide ? activeSlide : infinite ? slidesToShow : 0
  )
  const [dragStart, setDragStart] = useState(0)
  const [dragOffset, setDragOffset] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  const slideWidth = useMemo(() => `${100 / slidesToShow}%`, [slidesToShow])

  const extendedSlides = useMemo(() => {
    if (!infinite) return slides
    const beforeSlides = slides.slice(-slidesToShow)
    const afterSlides = slides.slice(0, slidesToShow)
    return [...beforeSlides, ...slides, ...afterSlides]
  }, [infinite, slides, slidesToShow])

  const showArrowsForScreen = useMemo(() => {
    if (slides.length <= 1 || slides.length < slidesToShow) return false
    return showArrows !== undefined ? showArrows : isMobile ? false : true
  }, [isMobile, slides, slidesToShow, showArrows])

  const maxIndex = slides.length - slidesToShow

  const handleTransitionEnd = useCallback(() => {
    setIsTransitioning(false)

    if (!infinite) return

    if (currentIndex >= slides.length) {
      setCurrentIndex(0)
      setOffset(slidesToShow)
    } else if (currentIndex < 0) {
      setCurrentIndex(maxIndex)
      setOffset(slides.length)
    }
  }, [currentIndex, infinite, slides.length, maxIndex, slidesToShow])

  const handleNext = useCallback(() => {
    if (isTransitioning) return
    if (!infinite && currentIndex >= maxIndex) return

    setIsTransitioning(true)
    setCurrentIndex((prev) => prev + slidesToScroll)
    setOffset((prev) => prev + slidesToScroll)
  }, [isTransitioning, infinite, currentIndex, maxIndex, slidesToScroll])

  const handlePrev = useCallback(() => {
    if (isTransitioning) return
    if (!infinite && currentIndex <= 0) return

    setIsTransitioning(true)
    setCurrentIndex((prev) => prev - slidesToScroll)
    setOffset((prev) => prev - slidesToScroll)
  }, [isTransitioning, infinite, currentIndex, slidesToScroll])

  const handleDotClick = useCallback(
    (index: number) => {
      if (isTransitioning) return

      setIsTransitioning(true)
      setCurrentIndex(index)
      setOffset(infinite ? index + slidesToShow : index)
    },
    [isTransitioning, infinite, slidesToShow]
  )

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    setIsDragging(true)
    setDragStart(e.touches[0].clientX)
    setIsTransitioning(false)
  }, [])

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (!isDragging) return

      const currentX = e.touches[0].clientX
      const diff = currentX - dragStart
      setDragOffset(diff)
    },
    [isDragging, dragStart]
  )

  const handleTouchEnd = useCallback(() => {
    if (!isDragging) return

    const threshold = window.innerWidth * 0.2
    if (Math.abs(dragOffset) > threshold) {
      if (dragOffset > 0) {
        handlePrev()
      } else {
        handleNext()
      }
    }

    setIsDragging(false)
    setDragOffset(0)
  }, [isDragging, dragOffset, handlePrev, handleNext])

  const getDisplayIndex = useCallback(() => {
    if (!infinite) return currentIndex
    if (currentIndex < 0) return maxIndex
    if (currentIndex >= slides.length) return 0
    return currentIndex
  }, [infinite, currentIndex, slides.length, maxIndex])

  const showPrevArrow = useMemo(
    () => infinite || currentIndex > 0,
    [infinite, currentIndex]
  )

  const showNextArrow = useMemo(
    () => infinite || currentIndex < maxIndex,
    [infinite, currentIndex, maxIndex]
  )

  const touchEventProps = useMemo(
    () =>
      isMobile
        ? {
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove,
            onTouchEnd: handleTouchEnd,
          }
        : {},
    [isMobile, handleTouchStart, handleTouchMove, handleTouchEnd]
  )

  useEffect(() => {
    if (!autoSlide || slides.length === 1 || isDragging) return

    const interval = setInterval(() => {
      if (!isTransitioning && (infinite || currentIndex < slides.length - 1)) {
        handleNext()
      }
    }, autoSlideInterval)

    return () => clearInterval(interval)
  }, [
    autoSlide,
    autoSlideInterval,
    isTransitioning,
    currentIndex,
    infinite,
    isDragging,
    slides.length,
    handleNext,
  ])

  useEffect(() => {
    if (typeof activeSlide !== 'number') {
      return
    }
    setCurrentIndex(activeSlide)
    setOffset(infinite ? activeSlide + slidesToShow : activeSlide)
  }, [activeSlide])

  useEffect(() => {
    handleSlideUpdate && handleSlideUpdate(currentIndex)
  }, [currentIndex])

  return {
    slides,
    extendedSlides,
    noOfSlidesUsed: slidesToShow,
    isTransitioning,
    isDragging,
    offset,
    dragOffset,
    slideWidth,
    touchEventProps,
    showPrevArrow,
    showNextArrow,
    showArrowsForScreen,
    handleTransitionEnd,
    handleNext,
    handlePrev,
    handleDotClick,
    getDisplayIndex,
  }
}

export default useCarousel
