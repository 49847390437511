import React, {useMemo} from 'react'
import {
  GenericSliderWrapper,
  SliderControls,
  GenericSliderContainer,
  OuterSliderWrapper,
} from './styles'
import {ISliderProps} from './types'
import {useDeviceTypeContext} from '../../../contexts/DeviceType'
import {ArrowRightWhite} from '@web-components'
import {ArrowLeftWhite} from '@web-components'
import {useSlider} from './useSlider'

function Slider(props: ISliderProps) {
  const {
    slidesToShow = 1,
    slidesToShowDesktop,
    aspectRatio = 1,
    onSlideChange = () => null,
    children,
    showArrows,
    showPeek = true,
    peekVariant = 'SMALL',
    itemCount,
    arrowPosition = 'outside', // New prop with default value
    isMobileView = false,
    activeSlide,
    customOuterContainerClassName = '',
  } = props

  const {isMobile} = useDeviceTypeContext()

  const validatedNoOfSlides = useMemo(() => {
    const validNoOfSlides = isMobile
      ? Math.floor(slidesToShow)
      : slidesToShowDesktop
      ? Math.floor(slidesToShowDesktop)
      : Math.floor(slidesToShow)
    return itemCount < validNoOfSlides ? itemCount : validNoOfSlides
  }, [isMobile, itemCount, slidesToShow, slidesToShowDesktop])

  const {
    sliderRef,
    noOfSlidesUsed,
    currentSlide,
    isEndOfScroll,
    imageDimensions,
    handleNextClick,
    handlePrevClick,
    peekSize,
    showArrowsForScreen,
  } = useSlider({
    noOfSlides: validatedNoOfSlides,
    aspectRatio,
    onSlideChange,
    showPeek,
    peekVariant,
    itemCount,
    showArrows,
    isMobile,
    activeSlide,
  })

  return (
    <OuterSliderWrapper className={customOuterContainerClassName}>
      <GenericSliderWrapper aria-label="Slider" arrowPosition={arrowPosition}>
        <GenericSliderContainer
          ref={sliderRef}
          noOfSlides={noOfSlidesUsed}
          peekSize={peekSize}
          isMobileView={isMobileView || isMobile}
        >
          {children({imageDimensions})}
        </GenericSliderContainer>

        {showArrowsForScreen && (
          <SliderControls
            arrowPosition={arrowPosition}
            isMobileView={isMobileView || isMobile}
          >
            <button
              className={`slider-button left ${
                currentSlide > 0 ? 'arrow-visible' : 'arrow-hidden'
              }`}
              onClick={handlePrevClick}
              aria-label="Previous Slide"
            >
              <ArrowLeftWhite aria-hidden />
            </button>

            <button
              className={`slider-button right ${
                !isEndOfScroll ? 'arrow-visible' : 'arrow-hidden'
              }`}
              onClick={handleNextClick}
              aria-label="Next Slide"
            >
              <ArrowRightWhite aria-hidden />
            </button>
          </SliderControls>
        )}
      </GenericSliderWrapper>
    </OuterSliderWrapper>
  )
}

export default Slider
