import styled from 'styled-components'

export const ProductOverlayTagContainer = styled.div<{
  borderColor?: string
  bgColor?: string
  labelColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay-container {
    align-self: center;
    border-radius: var(--dls-size-4) var(--dls-size-4) 0px 0px;
    border: 0.5px solid ${(props) => props?.borderColor || '#e7f1ff'};
    padding: 0px var(--dls-size-4);
    background-color: ${(props) =>
      props?.bgColor || 'var(--dls-highlighted-tag-background-color)'};
    position: absolute;
    bottom: 0;
    line-height: 0;
    padding-top: var(--dls-size-2);
    z-index: 1;
  }
  .overlay-text {
    color: ${(props) => props?.labelColor || 'var(--dls-primary-text-colors)'};
  }

  .out-of-stock-container {
    align-self: center;
    border-radius: var(--dls-size-4) var(--dls-size-4) 0px 0px;
    border: 0.5px solid #e7f1ff;
    padding: 0px var(--dls-size-4);
    background-color: var(--dls-highlighted-tag-background-color);
    position: absolute;
    bottom: 0;
  }
`
